import React, { useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import './index.css';
import App from './App';
import SignUp from './Pages/Register';
import { SignUpContextProvider } from './Contexts/SignUpContext';
import Login from './Pages/Login';
import AdminLogin from './Pages/AdminLogin';
import Confirmation from './Pages/ConfirmMail';
import ForgotPassword from './Pages/ForgotPassword';
import RequestOTP from './Pages/RequestOTP';
import VerifyOTP from './Pages/VerifyOTP';
import Dashboard from './Pages/Dashboard';
import Layout from './Pages/Layout';
import Products from './Pages/MyProducts';
import Home from './Pages/Home';
import Plans from './Pages/Plans';
import ProductList from './Pages/Products';
import NewQuotation from './Pages/NewQuoation';
import Orders from './Pages/Orders';
import Quotes from './Pages/Quotes';
import Invoice from './Pages/Invoice';
import Header from './Pages/Header';
import Report from './Pages/Report';
import { SessionContextProvider, useSession } from './Contexts/SessionContext';
import Profilepage from './Pages/Profilepage';
import Customer from './Pages/Customer';
import Newcustomer from './Pages/Newcustomer';
import NewInvoice from './Pages/NewInvoice';
const AppRouter = () => {
  const { isLoggedIn } = useSession();
  const navigate = useNavigate();
  const location = useLocation();
  useLayoutEffect(() => {
    if (!isLoggedIn && !['/confirm/', '/signup', '/requestotp', '/verifyotp', '/forgotpassword']
      .some(route => location.pathname.startsWith(route))) {
      navigate('/', { replace: true });
    }
  }, [isLoggedIn, location.pathname, navigate]);
  return (
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/login" element={<Login />} />
      <Route path="/adminlogin" element={<AdminLogin />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/requestotp" element={<RequestOTP />} />
      <Route path="/verifyotp" element={<VerifyOTP />} />
      <Route path="/confirm/:token" element={<Confirmation />} />
      {isLoggedIn ? (
        <>
          <Route path="/header" element={<Header />} />
          <Route path="/dashboard" element={<Layout title="Dashboard"><Dashboard /></Layout>} />
          <Route path="/home" element={<Layout title="Home"><Home /></Layout>} />
          <Route path="/products" element={<Layout title="Product/Service"><Products /></Layout>} />
          <Route path="/customer" element={<Layout title="Customer"><Customer /></Layout>} />
          <Route path="/plans" element={<Layout title="Products / Service"><Plans /></Layout>} />
          <Route path="/productlist" element={<Layout title="Products / Service"><ProductList /></Layout>} />
          <Route path="/quotationnew" element={<Layout title="New Quotation"><NewQuotation /></Layout>} />
          <Route path="/invoicenew" element={<Layout title="New Invoice"><NewInvoice /></Layout>} />
          <Route path="/quotation" element={<Layout title="Quotation"><Quotes /></Layout>} />
          <Route path="/orders" element={<Layout title="Orders"><Orders /></Layout>} />
          <Route path="/invoice" element={<Layout title="Invoice"><Invoice /></Layout>} />
          <Route path="/report" element={<Layout title="Report"><Report /></Layout>} />
          <Route path="/profile" element={<Layout title="Profile"><Profilepage /></Layout>} />
          <Route path="/newcustomer" element={<Layout title="New Customer"><Newcustomer /></Layout>} />
        </>) : (
        <Route path="*" element={<App />} />
      )}
    </Routes>
  );
};
const rootElement = document.getElementById('root');
document.body.style.backgroundColor = "#F7F7F7";
if (rootElement) {
  ReactDOM.render(
    <React.StrictMode>
      <Router>
        <SessionContextProvider>
          <SignUpContextProvider>
            <AppRouter />
          </SignUpContextProvider>
        </SessionContextProvider>
      </Router>
    </React.StrictMode>,
    rootElement
  );
}